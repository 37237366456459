import { trackPromise } from "react-promise-tracker";
import Helper from "../../Helper";
import { Activity } from "../Activity/activityTypes";

export function getActivitiesForProject(
    team_subdomain: string,
    project_token: string,
    future: boolean = false
): Promise<Activity[]> {
    let url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        "/projects/" +
        project_token +
        "/activities";
    if (future) {
        url += "?future=true";
    }

    return trackPromise(
        fetch(url, {
            method: "get",
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }

                throw new Error("Network error.");
            })
            .then((data) => data.map((activity) => Activity.fromAPIObject(activity)))
            .catch(() => [])
    );
}

export function uploadScheduleForProject(
    team_subdomain: string,
    project_token: string,
    file: FormData
): Promise<string | null> {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        "/projects/" +
        project_token +
        "/activity_imports";

    return trackPromise(
        fetch(url, {
            method: "post",
            body: file,
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .then((data) => {
                return data.message;
            })
            .catch(() => {
                return null;
            })
    );
}

export function syncActivitiesForProject(
    team_subdomain: string,
    project_token: string,
    only_update: boolean = false
): Promise<Activity> {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        `/projects/${project_token}/activities`;

    return trackPromise(
        fetch(url, {
            method: "put",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                only_update: only_update,
            }),
            credentials: "include",
        }).then((data) => {
            if (data.ok) {
                return data.json();
            }
            throw new Error("Network error.");
        })
    );
}
