import { trackPromise } from "react-promise-tracker";
import Helper from "../../Helper";
import { Submittal } from "../Submittal/submittalTypes";

export function getSubmittals(team_subdomain: string, project_token: string): Promise<Submittal[]> {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        `/projects/${project_token}/submittals`;

    return trackPromise(
        fetch(url, {
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                return [];
            })
            .then((response) => {
                return response.map((sub) => Submittal.fromAPIObject(sub));
            })
            .catch(() => {
                return [];
            })
    );
}

export function createSubmittalForProject(
    team_subdomain: string,
    project_token: string,
    submittal: Submittal
): Promise<Submittal | null> {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        `/projects/${project_token}/submittals`;

    return trackPromise(
        fetch(url, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                submittal: submittal.toAPIObject(),
            }),
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .catch(() => {
                return null;
            })
    );
}

export function syncSubmittalsForProject(
    team_subdomain: string,
    project_token: string,
    only_update: boolean = true
): Promise<Submittal> {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        `/projects/${project_token}/submittals`;

    return trackPromise(
        fetch(url, {
            method: "put",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                only_update: only_update,
            }),
            credentials: "include",
        }).then((data) => {
            if (data.ok) {
                return data.json();
            }
            throw new Error("Network error.");
        })
    );
}
