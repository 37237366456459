import Permission from "./Permission";

class UserInfo {
    id: number;
    sub: string;
    fullname: string;
    nickname: string;
    created_at: string;
    updated_at: string;
    preferred_language: string;
    guest: boolean;
    receive_web_notifications: boolean;
    receive_app_notifications: boolean;
    prompted_for_notification_preferences: boolean;
    token: string;
    permissions: [
        {
            id: number;
            user_id: number;
            team_id: number;
            permission: string;
            project_id: number;
            created_at: string;
            updated_at: string;
        }
    ];
    mobiles: [
        {
            id: number;
            user_id: number;
            mobile: string;
            mobile_type: string;
            created_at: string;
            updated_ap: string;
            verified: boolean;
            verification_token: string;
            receive_notifications: boolean;
        }
    ];
    emails: [
        {
            id: number;
            user_id: number;
            email: string;
            email_type: string;
            created_at: string;
            updated_ap: string;
            verified: boolean;
            verification_token: string;
            receive_notifications: boolean;
        }
    ];
    picture: { url: string };
    user_preferences: { theme: string };

    constructor(user_info) {
        this.id = user_info.id;
        this.fullname = user_info.fullname;
        this.nickname = user_info.nickname;
        this.created_at = user_info.created_at;
        this.updated_at = user_info.updated_at;
        this.preferred_language = user_info.preferred_language;
        this.guest = user_info.guest;
        this.receive_web_notifications = user_info.receive_web_notifcations;
        this.receive_app_notifications = user_info.receive_app_notifications;
        this.prompted_for_notification_preferences = user_info.prompted_for_notification_preferences;
        this.token = user_info.token;
        this.permissions = user_info.permissions;
        this.mobiles = user_info.mobiles;
        this.emails = user_info.emails;
        this.picture = user_info.picture;
        this.user_preferences = user_info.user_preferences;
    }

    permission(permission: Permission, team: number, project: number | null): boolean {
        return this.permissions.some((p) => {
            return (
                p.team_id === team && p.project_id === project && new Permission(p.permission).perm === permission.perm
            );
        });
    }

    addPermission(permission: Permission, team: number, project: number | null) {
        if (!this.permission(permission, team, project)) {
            this.permissions.push({
                id: null,
                user_id: this.id,
                team_id: team,
                permission: permission.perm,
                project_id: project,
                created_at: new Date().toUTCString(),
                updated_at: new Date().toUTCString(),
            });
        }
    }

    removePermission(permission: Permission, team: number, project: number | null) {
        const index = this.permissions.findIndex((p) => {
            return (
                p.team_id === team && p.project_id === project && new Permission(p.permission).perm === permission.perm
            );
        });

        if (index > -1) {
            this.permissions.splice(index, 1);
        }
    }
}

export default UserInfo;
