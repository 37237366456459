import { sub } from "date-fns";
import { Submittal } from "../Submittal/submittalTypes";
import { Material } from "./material";
import { Order } from "./order";

export class MaterialGroup {
    id: number;
    archived: boolean;
    material_group_name: string;
    project_unique_token: string;
    project_id: number;
    initialized: boolean;
    created_at: Date;
    updated_at: Date;
    team: any | null;
    vendor: any | null;
    spec_number: string;
    submittal_number: string;
    lead_time: number | null;
    required_on_jobsite: Date | null;
    check_in_frequency: number | null;
    approval_date: Date | null;
    submit_date: Date | null;
    review_duration: number;
    order_delay: number;

    submittals: Submittal[];

    materials: Material[];

    static fromAPIObject(material_group: any): MaterialGroup {
        const new_material_group = new MaterialGroup();
        new_material_group.id = material_group.id;
        new_material_group.archived = material_group.archived;
        new_material_group.material_group_name = material_group.material_group_name;
        new_material_group.project_unique_token = material_group.project_unique_token;
        new_material_group.project_id = material_group.project_id;
        new_material_group.initialized = material_group.initialized;
        new_material_group.created_at = new Date(material_group.created_at);
        new_material_group.updated_at = new Date(material_group.updated_at);
        new_material_group.team = material_group.team;
        new_material_group.vendor = material_group.vendor;
        new_material_group.spec_number = material_group.spec_number;
        new_material_group.submittal_number = material_group.submittal_number;
        new_material_group.lead_time = material_group.lead_time;
        new_material_group.required_on_jobsite = material_group.required_on_jobsite
            ? new Date(material_group.required_on_jobsite)
            : null;
        new_material_group.check_in_frequency = material_group.check_in_frequency;
        new_material_group.approval_date = material_group.approval_date ? new Date(material_group.approval_date) : null;
        new_material_group.submit_date = material_group.submit_date ? new Date(material_group.submit_date) : null;
        new_material_group.review_duration = material_group.review_duration;
        new_material_group.order_delay = material_group.order_delay;

        new_material_group.materials =
            material_group.materials?.map((material) => Material.fromAPIObject(material)) || [];

        new_material_group.submittals =
            material_group.submittals?.map((submittal) => Submittal.fromAPIObject(submittal)) || [];

        return new_material_group;
    }

    toAPIObject() {
        return {
            id: this.id,
            archived: this.archived,
            material_group_name: this.material_group_name,
            project_unique_token: this.project_unique_token,
            initialized: this.initialized,
            spec_number: this.spec_number,
            submittal_number: this.submittal_number,
            lead_time: this.lead_time,
            required_on_jobsite: this.required_on_jobsite,
            check_in_frequency: this.check_in_frequency,
            approval_date: this.approval_date,
            submit_date: this.submit_date,
            review_duration: this.review_duration,
            order_delay: this.order_delay,
            team_id: this.team?.id,
            vendor_id: this.vendor?.id,
            submittal_ids: this.submittals.map((submittal) => submittal.id),
        };
    }

    // Get the orders that are in need of action, aka. need user input to make sure they are on track
    ordersInNeedOfAction(): Order[] {
        return this.materials.flatMap((material) => material.ordersInNeedOfAction());
    }

    submitBy(): Date | null {
        const approveBy = this.approveBy();

        if (!approveBy) {
            return null;
        }

        return sub(approveBy, { days: this.review_duration });
    }

    approveBy(): Date | null {
        const orderBy = this.nextOrderBy();

        if (!orderBy) {
            return null;
        }

        return sub(orderBy, { days: this.order_delay });
    }

    nextOrderBy(): Date | null {
        const validOrderBys = this.materials
            .filter((material) => !material.archived)
            .map((material) => material.nextOrderBy())
            .filter((order_by) => order_by != null);

        if (validOrderBys.length > 0) {
            const sortedOrderBys = validOrderBys.sort((a, b) => a.getTime() - b.getTime());

            return sortedOrderBys[0];
        }

        return null;
    }

    nextROJ(): Date | null {
        const validROJs = this.materials
            .filter((material) => !material.archived)
            .map((material) => material.nextROJ())
            .filter((roj) => roj != null);

        if (validROJs) {
            const sortedROJs = validROJs.sort((a, b) => a.getTime() - b.getTime());

            return sortedROJs[0];
        }

        return null;
    }
}
