import { trackPromise } from "react-promise-tracker";
import Helper from "../../Helper";
import { Equipment } from "../Equipment/equipmentTypes";

export function getEquipmentsForProject(projectToken: string, subdomain: string = ""): Promise<Equipment[]> {
    let url = "/projects/" + projectToken + "/equipment";

    if (subdomain && subdomain.length > 0) {
        url = Helper.baseUrlAltSubdomain(subdomain) + url;
    }

    return fetch(url, {
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((res) => {
            if (res.ok) {
                return res.json();
            }

            throw new Error("Failed to get project equipments");
        })
        .then((res) => {
            return res.map((eq) => Equipment.fromAPIObject(eq));
        })
        .catch(() => {
            return new Array<Equipment>();
        });
}

export function createEquipmentForProject(
    project_token: string,
    type: string,
    name: string,
    delivery_hours_start: string,
    delivery_hours_end: string,
    use_custom_hours: boolean,
    open_date: string,
    close_date: string,
    temp_closures: {
        id: number | null;
        closure_name: string;
        closure_start: Date | null;
        closure_end: Date | null;
    }[],
    break_times: { break_name: string; break_start: Date | null; break_end: Date | null }[]
): Promise<object> {
    const url = "/projects/" + project_token + "/equipment";
    return trackPromise(
        fetch(url, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                type: type,
                name: name,
                delivery_hours_start: delivery_hours_start,
                delivery_hours_end: delivery_hours_end,
                use_custom_hours: use_custom_hours,
                open_date: open_date,
                close_date: close_date,
                closures: temp_closures,
                break_times: break_times,
            }),
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .then((data) => {
                return data;
            })
            .catch(() => {
                return null;
            })
    );
}

export function getUpcomingDeliveriesForEquipment(project_token: string, id: number): Promise<any[]> {
    const url = "/projects/" + project_token + "/equipment/" + id + "/associated_deliveries";

    return fetch(url)
        .then((res) => {
            if (res.ok) {
                return res.json();
            }

            throw new Error("Failed to get project equipments");
        })
        .then((res) => {
            return res;
        })
        .catch(() => {
            return [];
        });
}

export function getVendorsForProject(project_token: string, subdomain: string = ""): Promise<any[]> {
    let url = "/projects/" + project_token + "/deliveries/vendors";

    if (subdomain && subdomain.length > 0) {
        url = Helper.baseUrlAltSubdomain(subdomain) + url;
    }

    return fetch(url)
        .then((res) => {
            if (res.ok) {
                return res.json();
            }

            throw new Error("Failed to get project vendors");
        })
        .then((res) => {
            return res;
        })
        .catch(() => {
            return [];
        });
}

export function createVendorForProject(
    project_token: string,
    name: string,
    contact_info: { name: string; contact: string }[]
): Promise<any> {
    const url = "/projects/" + project_token + "/deliveries/vendors";
    return trackPromise(
        fetch(url, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                delivery_vendor: {
                    vendor_name: name,
                    delivery_vendor_users_attributes: contact_info,
                },
            }),
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .then((data) => {
                return data;
            })
            .catch(() => {
                return null;
            })
    );
}

export function updateVendorForProject(
    project_token: string,
    id: number,
    name: string,
    contact_info: { name: string; contact: string }[]
): Promise<object> {
    const url = "/projects/" + project_token + "/deliveries/vendors/" + id;
    return trackPromise(
        fetch(url, {
            method: "put",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                delivery_vendor: {
                    vendor_name: name,
                    delivery_vendor_users_attributes: contact_info,
                },
            }),
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .then((data) => {
                return data;
            })
            .catch(() => {
                return null;
            })
    );
}

export function deleteVendorForProject(project_token: string, id: number): Promise<boolean> {
    const url = "/projects/" + project_token + "/deliveries/vendors/" + id;

    return trackPromise(
        fetch(url, {
            method: "delete",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .then(() => {
                return true;
            })
            .catch(() => {
                return false;
            })
    );
}
