import { trackPromise } from "react-promise-tracker";

export function getAvailableTimesForDelivery(
    project_token: string,
    date: string,
    length: number,
    tagged_teams: number[],
    tagged_area: string | null,
    tagged_equipment: number[]
): Promise<string[]> {
    const url = `/projects/${project_token}/deliveries/availabilities`;
    return trackPromise(
        fetch(url, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                date: date,
                length: length,
                tagged_teams: tagged_teams,
                tagged_area: tagged_area,
                tagged_equipment: tagged_equipment,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                return [];
            })
            .then((response) => {
                return response;
            })
            .catch(() => {
                return [];
            })
    );
}

export function getConflictsForDelivery(
    project_token: string,
    requested_date_start: Date,
    requested_date_end: Date,
    drop_off_location: string,
    receiving_teams: number[] | any[],
    equipments: number[],
    edit_id: number | null
): Promise<{
    location_success: boolean;
    location_error_message: string;
    location_conflicts: any[];
    trades_success: boolean;
    trades_conflicts: any[];
    equipment_success: boolean;
    equipment_error_message: string;
    equipment_conflicts: any[];
    hours_success: boolean;
    hours_error_message: string;
}> {
    const url = `/projects/${project_token}/deliveries/availabilities`;
    return trackPromise(
        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                requested_date_start: requested_date_start,
                requested_date_end: requested_date_end,
                drop_off_location: drop_off_location,
                receiving_teams: receiving_teams,
                equipments: equipments,
                edit_id: edit_id,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                throw Error("Failed to put");
            })
            .then((response) => {
                return response;
            })
            .catch(() => {
                return null;
            })
    );
}
