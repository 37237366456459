export class EquipmentType {
    type: number = 0;
    typeName: string = "";

    static DELIVERY: EquipmentType = new EquipmentType(0, "DELIVERY");

    constructor(type: number, typeName: string) {
        this.type = type;
        this.typeName = typeName;
    }

    getName(): string {
        return this.typeName;
    }

    static fromString(event_string: string): EquipmentType {
        if (event_string.toUpperCase() === EquipmentType.DELIVERY.getName()) {
            return EquipmentType.DELIVERY;
        }

        return EquipmentType.DELIVERY;
    }
}

export class Equipment {
    id: number;
    type: EquipmentType;
    name: string;
    delivery_hours_start: Date | null;
    delivery_hours_end: Date | null;
    use_custom_hours: boolean;
    open_date: Date;
    close_date: Date;
    temp_closures: { id: number; closure_name: string; closure_start: Date | null; closure_end: Date | null }[];
    exception_times: { id: number; break_name: string; break_start: Date | null; break_end: Date | null }[];
    area: any;

    constructor(
        id: number,
        type: EquipmentType,
        name: string,
        delivery_hours_start: Date | null,
        delivery_hours_end: Date | null,
        use_custom_hours: boolean,
        open_date: Date,
        close_date: Date,
        temp_closures: { id: number; closure_name: string; closure_start: Date | null; closure_end: Date | null }[],
        exception_times: { id: number; break_name: string; break_start: Date | null; break_end: Date | null }[],
        area: any
    ) {
        this.id = id;
        this.type = type;
        this.name = name;
        this.delivery_hours_start = delivery_hours_start;
        this.delivery_hours_end = delivery_hours_end;
        this.use_custom_hours = use_custom_hours;
        this.open_date = open_date;
        this.close_date = close_date;
        this.temp_closures = temp_closures;
        this.exception_times = exception_times;
        this.area = area;
    }

    static fromAPIObject(equipment: any): Equipment {
        const exception_times = new Array<{
            id: number;
            break_name: string;
            break_start: Date | null;
            break_end: Date | null;
        }>();
        if (equipment.equipment_break_times) {
            equipment.equipment_break_times.map((break_time) => {
                exception_times.push({
                    id: break_time.id,
                    break_name: break_time.break_name,
                    break_start: break_time.break_start ? new Date(break_time.break_start) : null,
                    break_end: break_time.break_end ? new Date(break_time.break_end) : null,
                });
            });
        }

        const temp_closures = new Array<{
            id: number;
            closure_name: string;
            closure_start: Date | null;
            closure_end: Date | null;
        }>();

        if (equipment.equipment_temp_closures) {
            equipment.equipment_temp_closures.map((closure) => {
                temp_closures.push({
                    id: closure.id,
                    closure_name: closure.closure_name,
                    closure_start: closure.closure_start,
                    closure_end: closure.closure_end,
                });
            });
        }

        equipment.equipment_area = equipment.equipment_area ? equipment.equipment_area : null;

        return new Equipment(
            equipment.id,
            equipment.equipment_type ? EquipmentType.fromString(equipment.equipment_type) : EquipmentType.DELIVERY,
            equipment.equipment_name,
            equipment.delivery_hours_start ? new Date(equipment.delivery_hours_start) : null,
            equipment.delivery_hours_end ? new Date(equipment.delivery_hours_end) : null,
            equipment.use_custom_hours,
            equipment.open_date,
            equipment.close_date,
            temp_closures,
            exception_times,
            equipment.area
        );
    }
}
