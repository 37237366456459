import { trackPromise } from "react-promise-tracker";
import Helper from "../../Helper";
import { ChangeHistoryPoint, Material, Order, OrderCheckIn } from "../Materials/materialTypes";

export function getOrderNumberForProject(
    team_subdomain: string,
    project_token: string,
    material: Material
): Promise<number | null> {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        "/projects/" +
        project_token +
        "/materials/" +
        material.id +
        "/count";

    return trackPromise(
        fetch(url, {
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                return null;
            })
            .then((response) => {
                return response;
            })
            .catch(() => {
                return null;
            })
    );
}

export function createOrderForProject(
    team_subdomain: string,
    project_token: string,
    order: Order,
    material_id: number
): Promise<Order | null> {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        "/projects/" +
        project_token +
        "/orders";

    return trackPromise(
        fetch(url, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                order: order.toAPIObject(),
                material_id: material_id,
            }),
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .then((data) => {
                return Order.fromAPIObject(data);
            })
            .catch(() => {
                return null;
            })
    );
}

export function updateOrderForProject(
    team_subdomain: string,
    project_token: string,
    order: Order
): Promise<Order | null> {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        "/projects/" +
        project_token +
        "/orders/" +
        order.id;

    return trackPromise(
        fetch(url, {
            method: "put",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ order: order.toAPIObject() }),
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .then((data) => {
                return Order.fromAPIObject(data);
            })
            .catch(() => {
                return null;
            })
    );
}

export function deleteOrderForProject(
    team_subdomain: string,
    project_token: string,
    order: Order
): Promise<Order | null> {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        "/projects/" +
        project_token +
        "/orders/" +
        order.id;

    return trackPromise(
        fetch(url, {
            method: "delete",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({}),
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .then((data) => {
                return Order.fromAPIObject(data);
            })
            .catch(() => {
                return null;
            })
    );
}

export function createOrderCheckinForProject(
    team_subdomain: string,
    project_token: string,
    order: Order,
    order_checkin: OrderCheckIn
): Promise<any | null> {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        "/projects/" +
        project_token +
        "/orders/" +
        order.id +
        "/order_check_ins";

    return trackPromise(
        fetch(url, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                order_check_in: order_checkin.toAPIObject(),
                files: order_checkin.files,
            }),
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .then((data) => {
                return data;
            })
            .catch(() => {
                return null;
            })
    );
}

export function uploadAttachmentForOrderCheckin(
    team_subdomain: string,
    project_token: string,
    order: Order,
    order_checkin: OrderCheckIn,
    file: FormData
): Promise<any | null> {
    const url =
        Helper.baseUrlAltSubdomain(team_subdomain ? team_subdomain : "projects") +
        "/projects/" +
        project_token +
        "/orders/" +
        order.id +
        "/order_check_ins/" +
        order_checkin.id +
        "/add_attachment";

    return trackPromise(
        fetch(url, {
            method: "post",
            body: file,
            credentials: "include",
        })
            .then((data) => {
                if (data.ok) {
                    return data.json();
                }
                throw new Error("Network error.");
            })
            .then((data) => {
                return data;
            })
            .catch(() => {
                return null;
            })
    );
}

export function getOrderCheckinsForProject(
    team_subdomain: string,
    project_token: string,
    order: Order
): Promise<OrderCheckIn[]> {
    const url = `${Helper.baseUrlAltSubdomain(
        team_subdomain ? team_subdomain : "projects"
    )}/projects/${project_token}/orders/${order.id}/order_check_ins`;

    const options = {
        method: "get",
    };

    return Helper.plotFetch(url, options, true)
        .then((data) => {
            if (data.ok) {
                return data.json();
            }
            throw new Error("Network error.");
        })
        .then((data) => {
            return data.map((checkin: any) => OrderCheckIn.fromAPIObject(checkin));
        })
        .catch(() => {
            return [];
        });
}

export function getOrderAuditsForProject(
    team_subdomain: string,
    project_token: string,
    order: Order
): Promise<ChangeHistoryPoint[]> {
    const url = `${Helper.baseUrlAltSubdomain(
        team_subdomain ? team_subdomain : "projects"
    )}/projects/${project_token}/orders/${order.id}/order_audits`;

    const options = {
        method: "get",
    };

    return Helper.plotFetch(url, options, true)
        .then((data) => {
            if (data.ok) {
                return data.json();
            }
            throw new Error("Network error.");
        })
        .then((data) => {
            return data.map((checkin: any) => ChangeHistoryPoint.fromAPIObject(checkin));
        })
        .catch(() => {
            return [];
        });
}
